import { PAGE_KEY_BUYING_GUIDE } from "@constants";
import { MEDIA_ORIENTATION } from "@prop-types/ImageOrientationProps";
import { scrollIntoView } from "@utils/functions";
import { findDOMNode } from "react-dom";
import { mergeTemplateItems } from "../common/utils";
import { createLazyTemplateItem } from "../utils";

const TEMPLATE_KEY = "sites/BuyingGuideTemplate-Cooker";

const BuyingGuideTemplate = props => {
  const jumpRefs = {};

  /**
   * @description This is a React like forwardRef constructor, without React and its `strict` warnings
   * @param {number} key A key associated with the referecen
   * @returns {function} Returns the ref factory
   */
  const forwardRef = key => ref => {
    jumpRefs[key] = ref;
  };

  const onJump = (e, i) => {
    if (jumpRefs[i]) {
      scrollIntoView(findDOMNode(jumpRefs[i]));
    }
  };

  const defaultTemplate = [
    {
      __comment__: "page heading",
      component: "core/TitledParagraph",
      props: { ...props.buyGuide.lead, titleAs: "h1" }
    },
    {
      __comment__: "card deck anchors",
      component: "CardDeck/Overlay/Row",
      props: {
        className: "cursor-pointer carddeck anchors",
        title: null,
        hasButton: false,
        items: props.buyGuideAnchors.items.map((item, i) => ({
          ...item,
          // i-index refers the childRef forward-created (see `forwardRef`)
          onClick: e => onJump(e, i)
        })),
        itemsPerRow: 4
      }
    },
    {
      __comment__: "whatIsRangeCooker",
      component: "core/Media",
      props: {
        ...props.buyGuide.whatIsRangeCooker.lead,
        img: {
          ...props.buyGuide.whatIsRangeCooker.lead.img,
          className: "my-auto"
        },
        imageOrientation: MEDIA_ORIENTATION.right,
        md: 4,
        as: "h2",
        childRef: forwardRef(0) // 1-index as the preferred/targeted element within props.buyGuideAnchors.items
      }
    },
    {
      __comment__: "choice of details",
      component: "core/TitledParagraph",
      props: {
        ...props.buyGuide.whatIsRangeCooker.choiceOfDetails.lead,
        as: "h3"
        //childRef: forwardRef(0) // 1-index as the preferred/targeted element within props.buyGuideAnchors.items
      }
    },
    {
      __comment__: "choice of details table",
      component: "core/TableComponent",
      props: {
        visible: props.buyGuide.whatIsRangeCooker.choiceOfDetails.table.visible,
        header: props.buyGuide.whatIsRangeCooker.choiceOfDetails.table.header,
        rows: props.buyGuide.whatIsRangeCooker.choiceOfDetails.table.rows,
        bordered: true,
        hover: true,
        responsive: true
      }
    },
    {
      __comment__: "available sizes",
      component: "core/Media",
      props: {
        ...props.buyGuide.whatIsRangeCooker.sizes.lead,
        img: {
          ...props.buyGuide.whatIsRangeCooker.sizes.lead.img,
          className: "my-auto"
        },
        imageOrientation: MEDIA_ORIENTATION.right,
        md: 4,
        as: "h3",
        className: "border-bottom"
        //childRef: forwardRef(1) // 1-index as the preferred/targeted element within props.buyGuideAnchors.items
      }
    },
    {
      __comment__: "which hob should I choose",
      component: "core/TitledParagraph",
      props: {
        ...props.buyGuide.whatStoveToChoose.lead,
        titleAs: "h2",
        childRef: forwardRef(1) // 1-index as the preferred/targeted element within props.buyGuideAnchors.items
      }
    },
    {
      __comment__: "which hob should I choose - paragraphs",
      component: "Newspaper/Jump",
      props: {
        items: props.buyGuide.whatStoveToChoose.paragraphs.map(item => ({
          ...item,
          imageOrientation: "top"
        }))
      }
    },
    {
      __comment__: "which oven should I choose",
      component: "core/TitledParagraph",
      props: {
        ...props.buyGuide.whatOvenToChoose.lead,
        titleAs: "h2",
        childRef: forwardRef(2) // 1-index as the preferred/targeted element within props.buyGuideAnchors.items
      }
    },
    {
      __comment__: "which oven should I choose - paragraphs",
      component: "Newspaper/Jump",
      props: {
        items: props.buyGuide.whatOvenToChoose.paragraphs.map(item => ({
          ...item,
          imageOrientation: "top"
        }))
      }
    },
    {
      __comment__: "built-in lead",
      component: "core/TitledParagraph",
      props: {
        ...props.buyGuide.builtIn.lead,
        titleAs: "h2",
        childRef: forwardRef(3) // 1-index as the preferred/targeted element within props.buyGuideAnchors.items
      }
    },
    {
      __comment__: "built-in card images",
      component: "CardDeck/Image/Footnote",
      props: {
        title: "",
        items: props.buyGuide.builtIn.items,
        className: "border-bottom pb-4"
      }
    },
    {
      __comment__: "kitchen solutions",
      component: "core/TitledParagraph",
      props: {
        ...props.buyGuide.kitchenSolutions.lead,
        titleAs: "h2",
        childRef: forwardRef(4) // 1-index as the preferred/targeted element within props.buyGuideAnchors.items
      }
    },
    {
      __comment__: "kitchen solutions - paragraphs",
      component: "Newspaper/Jump",
      props: { items: props.buyGuide.kitchenSolutions.paragraphs }
    },
    {
      __comment__: "our brands",
      component: "core/TitledParagraph",
      props: {
        ...props.buyGuide.ourBrands.lead,
        titleAs: "h2",
        childRef: forwardRef(5) // 1-index as the preferred/targeted element within props.buyGuideAnchors.items
      }
    },
    {
      __comment__: "our brands - images",
      component: "Brands/SiteLogoList",
      props: {}
    },
    {
      __comment__: "faq - lead",
      component: "core/TitledParagraph",
      props: {
        ...props.buyGuide.faq.lead,
        titleAs: "h2",
        childRef: forwardRef(6)
      } // 2-index as the preferred/targeted element within props.buyGuideAnchors.items
    },
    {
      __comment__: "faq - items",
      component: "Product/CollapsibleFAQ",
      props: {
        items: props.buyGuide.faq.items
      }
    },
    ...props.widgets
  ].filter(item => Boolean(item.props));

  return mergeTemplateItems(defaultTemplate, props.templates).map(
    createLazyTemplateItem
  );
};

BuyingGuideTemplate.mapValueToProps = value => ({
  searchBanner: value.searchBanner,
  buyGuideAnchors: {
    items: value.buyGuideAnchors.items
  },
  buyGuide: value.buyGuide,
  productBrand: value.productBrand,
  imageFootnoteCardDeck: value.imageFootnoteCardDeck
});

BuyingGuideTemplate.mapValueToTemplateProps = {
  pageKey: PAGE_KEY_BUYING_GUIDE,
  templateKey: TEMPLATE_KEY
};

export default BuyingGuideTemplate;
