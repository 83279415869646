import { PAGE_KEY_ASSEMBLY_GUIDE } from "@constants";
import { scrollIntoView } from "@utils/functions";
import { findDOMNode } from "react-dom";
import { mergeTemplateItems } from "../common/utils";
import { createLazyTemplateItem } from "../utils";

const TEMPLATE_KEY = "sites/AssemblyGuideTemplate-Cooker";

const AssemblyGuideTemplate = props => {
  const jumpRefs = {};

  /**
   * @description This is a React like forwardRef constructor, without React and its `strict` warnings
   * @param {number} key A key associated with the referecen
   * @returns {function} Returns the ref factory
   */
  const forwardRef = key => ref => {
    jumpRefs[key] = ref;
  };

  const onJump = (e, i) => {
    if (jumpRefs[i]) {
      scrollIntoView(findDOMNode(jumpRefs[i]));
    }
  };

  const defaultTemplate = [
    {
      __comment__: "Provides the the lead paragraph",
      component: "core/TitledParagraph",
      props: { ...props.assemblyGuide.lead, titleAs: "h1" }
    },
    {
      __comment__: "card deck anchors",
      component: "CardDeck/Overlay/Row",
      props: {
        className: "cursor-pointer carddeck anchors",
        title: null,
        hasButton: false,
        items: props.assemblyGuideAnchors.items.map((item, i) => ({
          ...item,
          // i-index refers the childRef forward-created (see `forwardRef`)
          onClick: e => onJump(e, i)
        })),
        itemsPerRow: 4
      }
    },
    {
      __comment__: "installation gas",
      component: "core/Media",
      props: {
        ...props.assemblyGuide.installationGas.lead,
        as: "h2",
        imageOrientation: "right",
        childRef: forwardRef(0)
      }
    },
    ...props.assemblyGuide.installationGas.paragraphs.map(paragraph => ({
      __comment__: "installation gas paragraph",
      component: paragraph.img ? "core/Media" : "core/TitledParagraph",
      props: {
        ...paragraph,
        ...(paragraph.img
          ? { as: "h3", imageOrientation: "right" }
          : { titleAs: "h3" })
      }
    })),
    ...props.assemblyGuide.installationGas.otherParagraphs.map(paragraph => ({
      __comment__: "installation gas other paragraph",
      component: "core/TitledParagraph",
      props: {
        ...paragraph,
        titleAs: "h4"
      }
    })),
    {
      __comment__: "installation electric",
      component: "core/TitledParagraph",
      props: {
        ...props.assemblyGuide.installationElectric.lead,
        titleAs: "h2",
        childRef: forwardRef(1)
      }
    },
    {
      __comment__: "should do yourself",
      component: "core/TitledParagraph",
      props: {
        ...props.assemblyGuide.shouldDoYourself.lead,
        titleAs: "h2",
        childRef: forwardRef(2)
      }
    },
    {
      __comment__: "placing range cooker",
      component: "core/Media",
      props: {
        ...props.assemblyGuide.placingRangeCooker.lead,
        as: "h2",
        imageOrientation: "right"
      }
    },
    ...props.assemblyGuide.placingRangeCooker.paragraphs.map(paragraph => ({
      __comment__: "placing range cooker paragraph",
      component: "core/TitledParagraph",
      props: {
        ...paragraph,
        titleAs: "h3"
      }
    })),
    {
      __comment__: "faq - lead",
      component: "core/TitledParagraph",
      props: {
        ...props.assemblyGuide.faq.lead,
        titleAs: "h2",
        childRef: forwardRef(3)
      } // 2-index as the preferred/targeted element within props.buyGuideAnchors.items
    },
    {
      __comment__: "faq - items",
      component: "Product/CollapsibleFAQ",
      props: {
        items: props.assemblyGuide.faq.items
      }
    },
    ...props.widgets
  ];

  return mergeTemplateItems(defaultTemplate, props.templates).map(
    createLazyTemplateItem
  );
};

AssemblyGuideTemplate.mapValueToProps = value => ({
  assemblyGuideAnchors: {
    items: value.assemblyGuideAnchors.items
  },
  assemblyGuide: value.assemblyGuide,
  searchBanner: value.searchBanner
});

AssemblyGuideTemplate.mapValueToTemplateProps = {
  pageKey: PAGE_KEY_ASSEMBLY_GUIDE,
  templateKey: TEMPLATE_KEY
};

export default AssemblyGuideTemplate;
