import { PAGE_KEY_ASSEMBLY_GUIDE } from "@constants";
import { AssemblyGuidePageBS } from "@style-variables";
import { getComponentClassName } from "@utils/strings";
import { getScreenTemplates, mergeHelmet } from "../utils";

export default ({ siteId, i18n, graphql, screen, imgParams, siteContext }) => {
  const pageId = AssemblyGuidePageBS;

  // const { siteId, i18n, graphql, screen } = args;

  const defaultHelmet = {
    title: "assembly-guide-page-cooker",
    meta: {
      name: {
        description: "beskrivs hur ska en vara monteras"
        // keywords: "x,y,z"
      }
    }
  };

  const helmet = mergeHelmet(
    mergeHelmet(defaultHelmet, siteContext.helmet, screen),
    screen.helmet,
    screen
  );

  return {
    [PAGE_KEY_ASSEMBLY_GUIDE]: {
      id: pageId,
      helmet,
      className: getComponentClassName(pageId),
      items: getScreenTemplates(screen)
    }
  };
};
